import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';

import { LoginContextService } from '@mpk/shared/data-access';

import { NavigationGroup } from './navigation-item';

export function redirectToFirstChild(navigationGroup: NavigationGroup): CanActivateFn {
  return (): UrlTree => {
    const loginContextService = inject(LoginContextService);
    const router = inject(Router);

    const permissionContext = loginContextService.permissionContext();

    for (const child of navigationGroup.children) {
      if (
        child.permissions == null ||
        child.permissions.some((permission) => permissionContext.hasPermission(permission))
      ) {
        return router.createUrlTree([navigationGroup.route, child.route]);
      }
    }

    return router.createUrlTree(['error']);
  };
}
