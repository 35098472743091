import { DestroyRef, Injectable, NgZone, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, Subject, from, fromEvent, interval, of, throwError } from 'rxjs';
import { defaultIfEmpty, filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ZendeskApiDetailsService } from '@mp/shared/zendesk/util';

import { ZENDESK_OAUTH_SCOPES } from '../injection-tokens';

interface ZendeskCodeMessage {
  code: string;
  type: 'ZENDESK_OAUTH_CODE';
}

@Injectable()
export class ZendeskAuthService {
  private get baseUrl(): string {
    return this.zendeskApiUrl || '';
  }

  private zendeskApiUrl: string | null = null;

  private readonly ZENDESK_ACCESS_TOKEN_MESSAGE_TYPE = 'ZENDESK_OAUTH_CODE';

  private readonly REDIRECT_URI: string = `${location.origin}/assets/zendesk/zendesk.html`;

  private readonly AUTH_SCOPE: string = inject(ZENDESK_OAUTH_SCOPES);

  private readonly CODE_VERIFIER_LENGTH = 64;

  private codeVerifier = '';

  constructor(
    private readonly zendeskApiDetailsService: ZendeskApiDetailsService,
    private readonly ngZone: NgZone,
    private readonly destroyRef: DestroyRef,
  ) {
    this.zendeskApiDetailsService.zendeskApiUrl$
      .pipe(
        tap((zendeskUrl) => (this.zendeskApiUrl = zendeskUrl)),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  /**
   * Gets an access token for the Zendesk API. It runs the implicit grant flow in spawned popup window.
   * @param clientId - An OAuth client ID for Zendesk access.
   */
  getAuthToken(clientId: string): Observable<string | null> {
    if (!this.zendeskApiUrl) {
      return this.throwNoZendeskApiUrlError();
    }

    return this.runTokenAuthorizationCodeFlow(clientId);
  }

  private runTokenAuthorizationCodeFlow(clientId: string): Observable<string | null> {
    return from(this.openAuthFlowWindow(clientId)).pipe(
      switchMap((window) => {
        if (!window) {
          return of(null);
        }

        const endpoint = `${this.baseUrl}/oauth/tokens`;

        return this.listenForCodeMessage(window as Window).pipe(
          filter((msg) => msg !== null),
          switchMap((code) => {
            const formData = new FormData();
            formData.append('grant_type', 'authorization_code');
            formData.append('code', code as string);
            formData.append('client_id', clientId);

            formData.append('redirect_uri', this.REDIRECT_URI);
            formData.append('scope', this.AUTH_SCOPE);
            formData.append('code_verifier', this.codeVerifier);

            const request: Promise<string> = fetch(endpoint, {
              body: formData,
              method: 'POST',
              mode: 'no-cors',
            })
              .then((res) => {
                console.log(res);
                return res.json();
              })
              .then((json) => {
                console.log(json);
                return json.authorization_code;
              });

            return from(request);
          }),
        );
      }),
    );
  }

  // Function to generate a random string of given length
  private generateRandomString(length: number) {
    const uint8Array: Uint8Array = new Uint8Array(length);
    const random = window.crypto.getRandomValues(uint8Array);
    const array = Array.from(random);
    this.codeVerifier = btoa(String.fromCharCode.apply(null, array))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  // Function to generate SHA-256 hash and encode it in base64
  private async sha256(base64String: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(base64String);
    const hash = await window.crypto.subtle.digest('SHA-256', data);
    let binary = '';
    const bytes = new Uint8Array(hash);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }

  private async openAuthFlowWindow(clientId: string): Promise<Window | null> {
    const endpoint = `${this.baseUrl}/oauth/authorizations/new`;

    this.generateRandomString(this.CODE_VERIFIER_LENGTH);
    const codeChallenge = await this.sha256(this.codeVerifier);

    const endpointParams: URLSearchParams = new URLSearchParams({
      response_type: 'code',
      redirect_uri: this.REDIRECT_URI,
      client_id: clientId,
      scope: this.AUTH_SCOPE,
      code_challenge_method: 'S256',
      code_challenge: codeChallenge,
    });

    return window.open(`${endpoint}?${endpointParams.toString()}`, undefined, 'width=800,height=600,popup');
  }

  private listenForCodeMessage(zendeskAuthWindow: Window): Observable<string | null> {
    const resultSubject$: Subject<string | null> = new Subject<string | null>();

    this.ngZone.runOutsideAngular(() =>
      fromEvent<MessageEvent<ZendeskCodeMessage>>(window, 'message')
        .pipe(
          filter((message) => this.isZendeskCodeMessage(message, zendeskAuthWindow)),
          first(),
          map(({ data }) => data.code),
          takeUntil(this.getWindowClosedObservable(zendeskAuthWindow)),
          defaultIfEmpty(null),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((code) => this.ngZone.run(() => resultSubject$.next(code))),
    );

    return resultSubject$.asObservable().pipe(first());
  }

  private isZendeskCodeMessage({ source, origin, data }: MessageEvent, zendeskAuthWindow: Window): boolean {
    return (
      source === zendeskAuthWindow &&
      origin === location.origin &&
      data?.type === this.ZENDESK_ACCESS_TOKEN_MESSAGE_TYPE &&
      typeof data.code === 'string'
    );
  }

  private getWindowClosedObservable(zendeskAuthWindow: Window): Observable<void> {
    const WINDOW_CLOSE_CHECK_INTERVAL = 200;

    return interval(WINDOW_CLOSE_CHECK_INTERVAL).pipe(
      map(() => zendeskAuthWindow.closed),
      filter(Boolean),
      map(() => undefined),
      first(),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  private throwNoZendeskApiUrlError(): Observable<never> {
    return throwError(() => new Error('Zendesk API URL not available'));
  }
}
