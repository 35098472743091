<ng-container *ngIf="(isNewsListLoading$ | async) === false; else loading">
  <div *ngIf="newsList$ | async as newsList" class="news-list">
    <ng-container *ngIf="hasCreateNewsForOwnOrganizationPermission()">
      <mp-create-news-bar
        *ngIf="!addingNewsFormVisible; else addingNewsForm"
        (createNewsClick)="toggleAddingNewsFormVisible()"
      ></mp-create-news-bar>

      <ng-template #addingNewsForm>
        <mp-news-item-edit-container
          [newsItem]="undefined"
          (editModeClose)="toggleAddingNewsFormVisible()"
        ></mp-news-item-edit-container>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="newsList.length > 0; else noNews">
      <ng-container *ngFor="let newsItem of newsList; trackBy: trackByFn">
        <mp-news-item-edit-container
          *ngIf="newsEditMap[newsItem.newsItemId]; else readNewsMode"
          [newsItem]="newsItem"
          (editModeClose)="toggleNewsItemEditMode(newsItem.newsItemId)"
        ></mp-news-item-edit-container>

        <ng-template #readNewsMode>
          <mp-news-item
            [newsItem]="newsItem"
            [expanded]="expandedNewsMap[newsItem.newsItemId]"
            (expandedChange)="updateNewsItemExpansionState(newsItem.newsItemId, $event)"
            (editModeChange)="toggleNewsItemEditMode(newsItem.newsItemId)"
          ></mp-news-item>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="newsListPaginationMetadata$ | async as pagination">
    <mat-paginator
      *ngIf="pagination.totalRowCount > 10"
      showFirstLastButtons
      [length]="pagination.totalRowCount"
      [pageIndex]="pagination.currentPage - 1"
      [pageSize]="pagination.limit"
      [pageSizeOptions]="[10, 25, 50, 100]"
      (page)="onPageChange($event)"
    >
    </mat-paginator>
  </ng-container>
</ng-container>

<ng-template #noNews>
  <p>Keine neuen Nachrichten.</p>
</ng-template>

<ng-template #loading>
  <mp-spinner></mp-spinner>
</ng-template>
