import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  MatLegacyPaginatorModule as MatPaginatorModule,
  LegacyPageEvent as PageEvent,
} from '@angular/material/legacy-paginator';
import { Observable, tap } from 'rxjs';

import { PaginationMetadata } from '@core/shared/domain';
import { FlyoutManager, getPageChangePagination } from '@core/shared/util';
import { SpinnerComponent } from '@core/ui';
import { CreateNewsBarComponent, NewsItemComponent } from '@mp/dashboard/newsfeed/ui';
import {
  NewsAssignedOrganisationsService,
  NewsfeedDataAccessModule,
  NewsfeedFacade,
} from '@mp/shared/kernel/newsfeed/data-access';
import { ListNewsItem, NewsItem } from '@mp/shared/kernel/newsfeed/domain';
import { LoginContextService } from '@mpk/shared/data-access';

import { NewsPermissions } from '../../permissions';
import { NewsItemEditContainerComponent } from '../news-item-edit-container/news-item-edit-container.component';

@Component({
  selector: 'mp-newsfeed',
  standalone: true,
  templateUrl: './newsfeed.component.html',
  styleUrls: ['./newsfeed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgFor,
    AsyncPipe,

    MatPaginatorModule,

    SpinnerComponent,
    CreateNewsBarComponent,
    NewsItemComponent,
    NewsItemEditContainerComponent,
    NewsfeedDataAccessModule,
  ],
  providers: [NewsAssignedOrganisationsService, { provide: FlyoutManager, useClass: FlyoutManager }],
})
export class NewsfeedComponent implements OnInit {
  readonly newsList$: Observable<ListNewsItem[]> = this.newsfeedFacade.newsList$.pipe(
    tap((newsList) => this.expandFirstNewsItem(newsList)),
  );

  readonly newsListPaginationMetadata$: Observable<PaginationMetadata | undefined> =
    this.newsfeedFacade.newsListPaginationMetadata$.pipe(tap(() => this.resetNewsEditMap()));

  readonly isNewsListLoading$: Observable<boolean> = this.newsfeedFacade.isNewsListLoading$;

  protected readonly hasCreateNewsForOwnOrganizationPermission = this.loginContextService.permission(
    NewsPermissions.CreateNewsForOwnOrganization,
  );

  addingNewsFormVisible = false;

  newsEditMap: Record<string, boolean> = {};

  expandedNewsMap: Record<string, boolean> = {};

  constructor(
    private readonly newsfeedFacade: NewsfeedFacade,
    private readonly loginContextService: LoginContextService,
  ) {}

  ngOnInit(): void {
    this.newsfeedFacade.fetchNewsList();
  }

  toggleAddingNewsFormVisible(): void {
    this.addingNewsFormVisible = !this.addingNewsFormVisible;
  }

  toggleNewsItemEditMode(newsItemId: string): void {
    const isItemEditModeActive = this.newsEditMap[newsItemId];
    this.newsEditMap = { ...this.newsEditMap, [newsItemId]: !isItemEditModeActive };
  }

  updateNewsItemExpansionState(newsItemId: string, isExpanded: boolean): void {
    this.expandedNewsMap = { ...this.expandedNewsMap, [newsItemId]: isExpanded };
  }

  onPageChange(pageEvent: PageEvent): void {
    this.resetExpandedNewsMap();
    this.newsfeedFacade.fetchNewsList(getPageChangePagination(pageEvent));
  }

  trackByFn(_index: number, { newsItemId }: NewsItem): string {
    return newsItemId;
  }

  private expandFirstNewsItem(newsList: NewsItem[]): void {
    if (!newsList.length) {
      return;
    }

    this.updateNewsItemExpansionState(newsList[0].newsItemId, true);
  }

  private resetNewsEditMap(): void {
    this.newsEditMap = {};
  }

  private resetExpandedNewsMap(): void {
    this.expandedNewsMap = {};
  }
}
